<template>
  <router-view />
</template>

<style lang="scss">
img {
  max-width: 100%;
  height: auto;
}
* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  outline: none;
}
// 自定义一个字体类型
@font-face {
  font-family: "bing";
  src: url(./assets/font/郭敬明字体.ttf);
}

// 搜索区域样式
.search {
  padding-bottom: 6px;
  font-size: 14px;
  color: #666;
  display: flex;
  align-items: center;
  * {
    margin-right: 5px;
  }
}
// 分页区域样式
.pagination {
  margin-top: 6px;
}
// 表单编辑项样式
.edititem {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  & > span {
    width: 70px;
    text-align: right;
    color: #666;
  }
  div {
    flex: 1;
  }
}
</style>
