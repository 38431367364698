import { createApp } from 'vue'
import App from './App.vue'
//导入当前项目的路由器对象
import router from './router'
//导入当前项目的全局状态管理对象
import store from './store'

//导入element-plus
import ElementPlus from 'element-plus'
//导入element-plus的样式
import 'element-plus/dist/index.css'

//导入中国国际化
import zhCn from 'element-plus/es/locale/lang/zh-cn'

//导入vue-quill富文本编辑器
import { QuillEditor } from '@vueup/vue-quill'
//导入vue-quill富文本编辑器的样式
import '@vueup/vue-quill/dist/vue-quill.snow.css';

// 执行mockjs（后台接口没有做好之前，自己模拟数据；后台接口做好后，注释掉下面的一行代码）
// import './mock'

createApp(App)
.use(store).use(router).use(ElementPlus,{
    locale: zhCn,
})
.component('QuillEditor', QuillEditor)
.mount('#app')
