//当前登录账户信息模块
export default {
    namespaced:true,
    state:{
        //用于保存当前登录账户信息
        admin:{
            name:'管理员',
            role:{}
        }
    },
    mutations:{
        setAdmin(state,val){
            state.admin = val
        }
    },
    actions:{
        setAdmin(store,val){
            store.commit('setAdmin',val)
        }
    }
}